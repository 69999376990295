import React from "react";
import { Accordion } from "flowbite-react";
import "./Pdp.css";

const PdpAccordion = ({ product }) => {
  return (
    <Accordion className="pdp-accordion">
      <Accordion.Panel>
        <Accordion.Title className="accordion-title">
          Descripción
        </Accordion.Title>
        <Accordion.Content>
          <p className="pdp-accordion-text">{product.description}</p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className="accordion-title">
          Especificaciones
        </Accordion.Title>
        <Accordion.Content>
        { product.other && 
           <p className="pdp-accordion-text">
           { product.other}
         </p>
          }
          { product.quantity && 
           <p className="pdp-accordion-text">
           {"m²/caja: " + product.quantity }
         </p>
          }
          { product.piece && 
           <p className="pdp-accordion-text">
           {"Pza/caja: " + product.piece + " pieza(s)"}
         </p>
          }
        {product.color?
         <p className="pdp-accordion-text">{`Color: ${ product.color.map(item => ` ${ item }`)}`}</p>
         : 
        null
      }
     
     
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};

export default PdpAccordion;
