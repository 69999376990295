function Seguridad() {
  return (
    <section className="seguridad-datos-container">
      <h1>TÉRMINOS Y CONDICIONES</h1>
      <p>•  Política de uso del sitio de internet.</p>

<p>•  Datos personales.</p>

<p>•  Fichas técnicas.</p>

<p>POLÍTICA DE USO DEL SITIO WEB</p>

<p>La presente política regula el uso de este sitio por parte del usuario, según se define más adelante, por lo que la utilización del sitio por parte del usuario, constituye la aceptación de los mismos en todos sus términos sin reserva alguna. Por lo anterior, le invitamos a que lea cuidadosamente la presente política y si tiene cualquier duda, se ponga en contacto por cualquiera de los siguiente medios:</p>

<p>Vía telefónica: 55 24513500 ext. 1100</p>

<p>correo electrónico: info@woodart.com.mx</p>

<p>ACEPTACIÓN DE CONDICIONES</p>

<p>Al hacer uso del sitio: https://woodart.com.mx. Usted acepta sujetarse a los presentes términos y condiciones por lo que deberá leerlo en forma completa y comprender sus alcances. La presente política será revisada periódicamente y puede ser modificada sin previo aviso al usuario. En consecuencia, es necesario que en forma periódica revise la presente política y conozca sus actualizaciones.</p>

<p>ALCANCE DE LA POLÍTICA</p>

<p>La presente política aplica al contenido del sitio: https://woodart.com.mx los vínculos y materiales asociados o relacionados al mismo. Por lo que cualquier vínculo que dirija a sitios de terceros se regirá por las políticas, términos y condiciones aplicables a dichos sitios, sin que LETSAC MÉXICO S.R.L. de C.V., empresas filiales, controladoras y subsidiarias tenga control sobre ello.</p>

<p>RESPONSABILIDAD DE LETSAC MÉXICO, S.R.L. DE C.V. (En lo sucesivo WOODART®)</p>

<p>WOODART® es la empresa responsable de la operación del sitio. Como tal, usted acepta que WOODART® queda eximido de cualquier responsabilidad relativa a la veracidad o calidad de la información ingresada por el usuario en este sitio, así como de cualquier daño o menoscabo, directos o indirectos, que puedan sufrir sus equipos o usted con motivo del uso del sitio.</p>

<p>WOODART® no representa ni aprueba ningún consejo, opinión o declaración difundida a través de este sitio por parte de terceros, por lo que las decisiones adoptadas por los usuarios con base en las mismas, son de la exclusiva responsabilidad de los usuarios.</p>

<p>Asimismo, WOODART® no se hace responsable por los enlaces que desde el sitio se puedan hacer a otros sitios, ni de la actividad o negociaciones que realice el usuario con dichos terceros, ya que la publicación de dichas ligas en este sitio de ninguna forma constituye un patrocinio, asociación o relación con tales sitios o las entidades que los operan.</p>

<p>OBLIGACIONES DEL USUARIO</p>

<p>Como usuario del sitio acepta que no empleará la información para otros fines que los estrictamente personales, excluyendo cualquier fin comercial o informativo no autorizado previamente, ni usará la información del sitio para promocionar negocios o servicios de la competencia o ajenos a WOODART®. Asimismo, usted se abstendrá de modificar la información contenida en el sitio, o de usarla como elemento para prácticas abusivas o de competencia desleal.</p>

<p>Al usar este sitio, usted acepta y reconoce que tiene prohibido recopilar, copiar o diseminar información de terceros, incluyendo nombres, correos electrónicos o cualquier otro dato personal, cuando no se cuente con autorización expresa para ello; evitará crear una identidad falsa o una dirección falsa de correo electrónico, u otra información que no sea veraz y evitará realizar actos orientados a engañar respecto de la identidad del usuario o el origen de sus mensajes; usar el sitio en cualquier forma contraria a la moral o la ética, incluyendo la transmisión a través del sitio de materiales ilegales, ofensivos o indecentes; impedir el buen uso del sitio a terceros; intentar ingresar al sitio o sitios asociados, o materiales confidenciales, usando indebidamente contraseñas u otros medios no permitidos, así como interferir o perturbar el uso normal del sitio; intentar o realizar actos para infectar el sitio o su red con virus u otros programas que afecten el normal funcionamiento del mismo; en general, realizar cualquier acto que sea contrario a la moral o las disposiciones legales vigentes y aplicables. En caso de que el usuario haga uso indebido del sitio, o incurra en alguna de las conductas prohibidas, o infrinja las políticas aquí descritas o cualquier otra norma aplicable, éste acepta pagar la compensación que sea suficiente para resarcir el daño, más cualquier suma procedente conforme a la legislación aplicable.</p>

<p>PRIVACIDAD DEL USUARIO</p>

<p>Le informamos que LETSAC MÉXICO, S.R.L de C.V. tratará sus datos personales para:</p>

<p>1.  Dar cumplimiento a obligaciones comerciales contraídas</p>

<p>2.  Darlo de alta en los sistemas internos de seguimiento</p>

<p>3.  Informarle sobre eventos, programas de lealtad, promociones de productos, descuentos, capacitaciones, servicios, publicaciones y novedades relacionadas con la red de productos WOODART®</p>

<p>4.  Realizar encuestas de satisfacción y evaluación de la calidad de nuestro servicio</p>

<p>5.  Estudios internos para el desarrollo comercial de nuevos productos y servicios WOODART®</p>

<p>La manifestación libre y voluntaria de su información constituye su consentimiento expreso para el tratamiento de sus datos personales en los términos y condiciones del Aviso de Privacidad Completo WOODART®. Para dudas y aclaraciones relacionadas con el presente aviso, por favor escríbanos a info@woodart.com.mx o visite el “Política de privacidad”.</p>

<p>DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL</p>

<p>Las marcas, imágenes, logotipos, e información de los productos, contenido en el sitio woodart.com.mx son propiedad de LETSAC MÉXICO S.R.L. de C.V. y/o de sus licenciantes, por lo que el usuario no podrá copiar, reproducir, modificar, descargar o generar nuevos contenidos a partir de aquellos a los que tenga acceso derivado de la compraventa de bienes a través de este sitio o de la navegación o uso de la misma.</p>

<p>Todos los textos, imágenes, esquemas, fotografías, videos, música, programas de cómputo, datos, formatos, logotipos, nombres, fórmulas comerciales y cualesquiera otros materiales a los que como usuario tenga acceso en el sitio o materiales relacionados, son propiedad exclusiva de WOODART®, sus empresas filiales o controladoras o controladas, en términos de la Ley de la Propiedad Industrial y/o de Derechos de Autor vigentes en México y/o cualquier otra jurisdicción en donde se comercialicen los productos de las marcas WOODART®, y no pueden ser utilizados, copiados, modificados, mutilados o imitados en ningún caso, sin la autorización previa y por escrito de WOODART®.</p>

<p>En caso de cualquier violación de dichos derechos de propiedad intelectual o industrial, facultarán a WOODART® para ejercer cualquier acción o derecho que le asista en contra del infractor, incluyendo acciones de carácter penal.</p>

<p>En caso de que como usuario suministre información u otros materiales al sitio, se acepta que es bajo su estricta responsabilidad, por lo que asume cualquier conflicto relativo a derechos de terceros, en especial de propiedad intelectual, liberando a WOODART® de cualquier responsabilidad sobre el particular. Asimismo, usted acepta que el uso que WOODART® realice de tales materiales es libre, y no estará sujeto al pago de contraprestación alguna, ni limitaciones de ningún tipo.</p>

<p>Todas las marcas, avisos y nombres comerciales que aparecen en este sitio son propiedad de WOODART®.</p>

<p>En los casos en que se hace uso de marcas que no son propiedad de WOODART®, así se indicará, sin que deba interpretarse que la inclusión de tales marcas supone un patrocinio o apoyo a las mismas.</p>

<p>EXCLUSIÓN DE RESPONSABILIDAD</p>

<p>WOODART® se reserva el derecho de establecer periodos de mantenimiento del sitio en el momento que sea conveniente a sus intereses o los sitios así lo requieran, sin necesidad de notificación previa al usuario</p>

<p>El usuario acepta y reconoce que la utilización de sitios de internet y plataformas en línea implica riesgos fuera del alcance de WOODART®, por lo que ésta será responsable de implementar las medidas de seguridad necesarias para la protección de la información que se llegue a intercambiar entre el usuario y WOODART®. No obstante lo anterior, el usuario reconoce que la vulneración de datos puede llegar a realizarse de forma fraudulenta como los ataques cibernéticos o de los llamados “hackers” y pese a las medidas de seguridad informática que WOODART® implemente para protección del sitio.</p>

<p>COMPETENCIA Y LEY APLICABLE</p>

<p>El usuario acepta y reconoce que al hacer uso de este sitio, acepta que todo lo relacionado al sitio, su operación y su utilización, deberá interpretarse de conformidad con la legislación aplicable. Por lo tanto, acepta que cualquier controversia derivada de su uso o funcionamiento, se someterá a la competencia de los Tribunales competentes en la Ciudad de México, no obstante el domicilio del usuario o el lugar desde donde ingrese al sitio.</p>

<p>DATOS PERSONALES</p>

<p>Todos los datos personales que sean recabados a través de la ”Plataforma”, se encuentran sujetos al Aviso de seguridad de datos de WOODART®, el cual se encuentra disponible en https://woodart.com.mx/ y serán tratados en los términos establecidos en la Política de uso del sitio. WOODART® sólo recabará su información con la finalidad de ubicar al “distribuidor” más cercano y realizar la entrega de los ”Productos”. </p>

<p>PROPIEDAD INDUSTRIAL</p>

<p>Las marcas, imágenes, logotipos, e información de los “Productos”, contenida en https://woodart.com.mx y la “Plataforma” son propiedad de WOODART® o de sus licenciantes, por lo que el Usuario no podrá copiar, reproducir, modificar, descargar o generar nuevos contenidos a partir de aquellos a los que tenga acceso derivado de la compraventa de bienes a través de la “Plataforma”, o de la navegación o uso de la misma.</p>
    </section>
  );
}
export default Seguridad;
