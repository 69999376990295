// import React, { useMemo, useEffect, useState } from "react";
// import { Accordion } from "flowbite-react";
// import { Link , useParams } from 'react-router-dom';
// import data from '../data/data.js'
// import "./Plp.css";

// const PlpAccordion = ({ products }) => {
//   const path = "todos"
//   const { category, subcategory, input, filters } = useParams();
//   let uniqueSubcategoriesArray;
//   let uniqueSizeArray;
//   let uniqueColorArray;

//   if (category==="todos"){
//     products = data.products
//   } else if (category){
//     products = data.products.filter((product) => product.category === category);
//   } 
//   if (input){
//       products = data.products.filter((item) => {
//         const categoryCondition =
//           item.category &&
//           item.category.toLowerCase().includes(input.toLowerCase());
//         const colorCondition =
//           item.color &&
//           item.color.find((uniqueColor) => {
//             return uniqueColor.toLowerCase().includes(input.toLowerCase());
//           });
//         const sizeCondition =
//           item.size &&
//           item.size.find((uniqueSize) => {
//             return uniqueSize.toLowerCase().includes(input.toLowerCase());
//           });
//         const nameCondition =
//           item.name && item.name.toLowerCase().includes(input.toLowerCase());
//         const collectionCondition =
//           item.name &&
//           item.subcategory.toLowerCase().includes(input.toLowerCase());
//         return (
//           colorCondition ||
//           sizeCondition ||
//           nameCondition ||
//           collectionCondition ||
//           categoryCondition
//         );
//       });
   

//   }

//   uniqueSubcategoriesArray = useMemo(() => {
//     const uniqueSubcategoriesSet = new Set(
//       products.map((category) => category.subcategory)
//     );
//     return Array.from(uniqueSubcategoriesSet);
//   }, [category]);

//   uniqueSizeArray = useMemo(() => {
//     // une todos los arrays size de productos en uno solo
//     const allSizes = products.flatMap(product => product.size);
//     const uniqueSizeSet = new Set(allSizes.filter(size => size !== undefined));
//     return Array.from(uniqueSizeSet);
//   }, [category]);

//   uniqueColorArray = useMemo(() => {
//     // une todos los arrays size de productos en uno solo
//     const allColor = products.flatMap(product => product.color);
//     const uniqueColorSet = new Set(allColor.filter(color => color !== undefined));
//     return Array.from(uniqueColorSet);
//   }, [category]);

//   return (
//     <Accordion className="plp-accordion">
//       <Accordion.Panel>
//         <Accordion.Title className="accordion-title">Colección</Accordion.Title>
//         <Accordion.Content>
//           {/*para filtrar cuando existe una busqueda*/}
//         { input?  uniqueSubcategoriesArray.map((collection, index) => (
//             <Link to={`/search/${input===undefined? path : input}/${collection}`} key={index} className={`plp-accordion-text + ${filters===collection ? "filter-selected" : null}`}>
//               {collection.charAt(0).toUpperCase() + collection.slice(1)}
//             </Link>
//           ))
//           :
//           uniqueSubcategoriesArray.map((collection, index) => (
//             <Link to={`/categoria/${category===undefined? path : category}/${collection}`} key={index} className={`plp-accordion-text + ${subcategory===collection ? "filter-selected" : null}`}>
//               {collection.charAt(0).toUpperCase() + collection.slice(1)}
//             </Link>
//           ))}
//         </Accordion.Content>
//       </Accordion.Panel>

//       <Accordion.Panel>
//         <Accordion.Title className="accordion-title">Medidas</Accordion.Title>
//         <Accordion.Content>
//         { input?  uniqueSizeArray.map((size, index) => (
//             <Link to={`/search/${input===undefined? path : input}/${size}`} key={index} className={`plp-accordion-text + ${filters===size ? "filter-selected" : null}`}>
//               {size}
//             </Link>
//           ))
//           :
//         uniqueSizeArray.map((size, index) => (
//             <Link to={`/categoria/${category===undefined? path : category}/${size}`} key={index} className={`plp-accordion-text + ${subcategory===size ? "filter-selected" : null}`}>
//               {size}
//             </Link>
//           ))}
//         </Accordion.Content>
//       </Accordion.Panel>

//       <Accordion.Panel>
//         <Accordion.Title className="accordion-title">Color</Accordion.Title>
//         <Accordion.Content>
//         { input?  uniqueColorArray.map((color, index) => (
//             <Link to={`/search/${input===undefined? path : input}/${color}`} key={index} className={`plp-accordion-text + ${filters===color ? "filter-selected" : null}`}>
//               {color}
//             </Link>
//           ))
//           :
//         uniqueColorArray.map((color, index) => (
//             <Link to={`/categoria/${category===undefined? path : category}/${color}`} key={index} className={`plp-accordion-text + ${subcategory===color ? "filter-selected" : null}`}>
//              {color}
//             </Link>
//           ))}
//         </Accordion.Content>
//       </Accordion.Panel>
//     </Accordion>
//   );
// };

// export default PlpAccordion;

import React, { useMemo } from "react";
import { Accordion } from "flowbite-react";
import { Link , useParams } from 'react-router-dom';
import "./Plp.css";

const PlpAccordion = ({ products }) => {
  const path = "todos"
  const { category, subcategory, input, filters } = useParams();
  let uniqueSubcategoriesArray;
  let uniqueSizeArray;
  let uniqueColorArray;

  // if (category==="todos"){
  //   products = data.products
  // } else if (category){
  //   products = data.products.filter((product) => product.category === category);
  // } 
  // if (input){
  //     products = data.products.filter((item) => {
  //       const categoryCondition =
  //         item.category &&
  //         item.category.toLowerCase().includes(input.toLowerCase());
  //       const colorCondition =
  //         item.color &&
  //         item.color.find((uniqueColor) => {
  //           return uniqueColor.toLowerCase().includes(input.toLowerCase());
  //         });
  //       const sizeCondition =
  //         item.size &&
  //         item.size.find((uniqueSize) => {
  //           return uniqueSize.toLowerCase().includes(input.toLowerCase());
  //         });
  //       const nameCondition =
  //         item.name && item.name.toLowerCase().includes(input.toLowerCase());
  //       const collectionCondition =
  //         item.name &&
  //         item.subcategory.toLowerCase().includes(input.toLowerCase());
  //       return (
  //         colorCondition ||
  //         sizeCondition ||
  //         nameCondition ||
  //         collectionCondition ||
  //         categoryCondition
  //       );
  //     });
   

  // }

  uniqueSubcategoriesArray = useMemo(() => {
    const uniqueSubcategoriesSet = new Set(
      products.map((category) => category.subcategory)
    );
    return Array.from(uniqueSubcategoriesSet);
  }, [category]);

  uniqueSizeArray = useMemo(() => {
    // une todos los arrays size de productos en uno solo
    const allSizes = products.flatMap(product => product.size);
    const uniqueSizeSet = new Set(allSizes.filter(size => size !== undefined));
    return Array.from(uniqueSizeSet);
  }, [category]);

  uniqueColorArray = useMemo(() => {
    // une todos los arrays size de productos en uno solo
    const allColor = products.flatMap(product => product.color);
    const uniqueColorSet = new Set(allColor.filter(color => color !== undefined));
    return Array.from(uniqueColorSet);
  }, [category]);

  return (
    <Accordion className="plp-accordion">
      <Accordion.Panel>
        <Accordion.Title className="accordion-title">Categoría</Accordion.Title>
        <Accordion.Content>
          {/*para filtrar cuando existe una busqueda*/}
        { input?  uniqueSubcategoriesArray.map((collection, index) => (
            <Link to={`/search/${input===undefined? path : input}/${collection}`} key={index} className={`plp-accordion-text + ${filters===collection ? "filter-selected" : null}`}>
              {collection.charAt(0).toUpperCase() + collection.slice(1)}
            </Link>
          ))
          :
          uniqueSubcategoriesArray.map((collection, index) => (
            <Link to={`/categoria/${category===undefined? path : category}/${collection}`} key={index} className={`plp-accordion-text + ${subcategory===collection ? "filter-selected" : null}`}>
              {collection.charAt(0).toUpperCase() + collection.slice(1)}
            </Link>
          ))}
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title className="accordion-title">Medidas</Accordion.Title>
        <Accordion.Content>
        { input?  uniqueSizeArray.map((size, index) => (
            <Link to={`/search/${input===undefined? path : input}/${size}`} key={index} className={`plp-accordion-text + ${filters===size ? "filter-selected" : null}`}>
              {size}
            </Link>
          ))
          :
        uniqueSizeArray.map((size, index) => (
            <Link to={`/categoria/${category===undefined? path : category}/${size}`} key={index} className={`plp-accordion-text + ${subcategory===size ? "filter-selected" : null}`}>
              {size}
            </Link>
          ))}
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>
        <Accordion.Title className="accordion-title">Color</Accordion.Title>
        <Accordion.Content>
        { input?  uniqueColorArray.map((color, index) => (
            <Link to={`/search/${input===undefined? path : input}/${color}`} key={index} className={`plp-accordion-text + ${filters===color ? "filter-selected" : null}`}>
              {color}
            </Link>
          ))
          :
        uniqueColorArray.map((color, index) => (
            <Link to={`/categoria/${category===undefined? path : category}/${color}`} key={index} className={`plp-accordion-text + ${subcategory===color ? "filter-selected" : null}`}>
             {color}
            </Link>
          ))}
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};

export default PlpAccordion;

