import logo from "../assets/Logo woodart blanco.png";
import "./Footer.css";
import pinterest from "../pinterest.svg";
import instagram from "../instagram.svg";
import facebook from "../facebook.svg";
import tiktok from "../tiktok.svg";

function Footer() {
  return (
    <footer>
      {/* <nav className="nav-servicios">
        <a className="footer-title" href="#">
          Servicios
        </a>
        <a href="#">Área Técnica</a>
        <a href="#">Manuales</a>
        <a href="#">Especificaciones</a>
      </nav> */}
      <article className="footer-images">
        <img src={logo} className="footer-logo" alt="logo" loading="lazy" />
        <div className="footer-allicons">
          <a
            href="https://www.facebook.com/profile.php?id=61551794971526"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icon" src={facebook} alt="fb" loading="lazy"/>
          </a>
          <a
            href="https://www.pinterest.com.mx/woodart_oficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icon" src={pinterest} alt="pinterest" loading="lazy" />
          </a>
          <a
            href="https://www.instagram.com/wood_art_oficial/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icon" src={instagram} alt="instagram" loading="lazy"/>
          </a>
          <a
            href="https://www.tiktok.com/@woodart_oficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icon" src={tiktok} alt="tiktok" loading="lazy"/>
          </a>
        </div>
        <span className="copyright-spam">Copyright ®WoodArt 2023</span>
        <div className="footer-bottom">
          <a href="/seguridad-de-datos">Seguridad de datos</a>
          <a href="/terminos-condiciones">Términos y condiciones</a>
        </div>
      </article>
    </footer>
  );
}

export default Footer;
