import React, { useState, useEffect } from "react";
import Card from "./Card.js";
import data from "../data/data.js";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import PlpAccordion from "./PlpAccordion.js";
import filtersvg from "../filter.svg";
import Filtermodal from "./Filtermodal.js";
import "./Plp.css";

function Plp() {
  const { category, subcategory, input, filters } = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  function onCloseModal() {
    setOpenModal(false);
  }
  useEffect(() => {
    const fetchProducts = async () => {
      const findProduct = getProductByCategory(
        category,
        subcategory,
        input,
        filters
      );
      setProducts(findProduct);
      setLoading(false);
    };

    fetchProducts();
  }, [category, subcategory, input, filters]);

  if (loading) {
    return <p></p>;
  }

  if (input && products.length === 0) {
    return (
      <section className="notfound-container-container">
        <section className="notfound-container">
          <span>
            ¡OOPS! No se encontrarón productos relacionados a tu búsqueda
          </span>
        </section>
      </section>
    );
  } else if (!input && products.length === 0) {
    return <NotFound />;
  }

  return (
    <section className="plp-image-content">
      { !input &&
         <div className="plp-image-container">
         <span>{subcategory? subcategory.toUpperCase(): category && category !="todos"? category.toUpperCase(): "PRODUCTOS"}</span>
         <span className="image-subtitle">{category==="lvt" ? "Piso Vinílico de Alta Calidad": category==="spc" ? "Piso Vinílico Multicapa":"" }</span>
         <img src={require('../assets/' + products[0].image + '.webp')} alt="banner-title-img"/>
       </div>
      }
     
      <section className="plp-container">
        <section className={"filter-container"}>
          <button className="filter-button" onClick={() => setOpenModal(true)}>
            {" "}
            <img className="filter-svg" src={filtersvg} alt="filter-svg"></img> Filtro
          </button>
          <Filtermodal
            initialProducts={products}
            openModal={openModal}
            onCloseModal={onCloseModal}
            setOpenModal={setOpenModal}
          ></Filtermodal>
          <div className="plp-accordion-desktop">
            <PlpAccordion products={products}></PlpAccordion>
          </div>
        </section>
        <section className={"plp-product-container"}>

          {products.map((product) => (
            <Card key={product.id} product={product} />
          ))}
        </section>
      </section>
    </section>
  );
}

const getProductByCategory = (category, subcategory, input, filters) => {
  let result;

  //cuando se ingresa desde navbar a productos
  if (category === "todos") {
    result = data.products;
  } else if (category) {
    result = data.products.filter((product) => product.category === category);
  }
  
    //cuando se ingresa desde buscador
    if (input && input.trim() !== '') {
      let array = [];
      let newInput = input.toLowerCase().split(" ");
      data.products.forEach((item) => {
          let coincidencia = 0;
          for (let i = 0; i < newInput.length; i++) {
              for (const [key, value] of Object.entries(item)) {
                  let newValue = value;
                  if (typeof (newValue) !== "string") {
                      newValue = newValue.toString();
                  }
                  if (newValue.toLowerCase().includes(newInput[i])) {
                      coincidencia++;
                      break; // Salir del bucle interno
                  }
              }
          }
          if (coincidencia === newInput.length) {
              array.push(item);
          }
      });
      result = array;  

    // result = data.products.filter((item) => {
    //   const categoryCondition =
    //     item.category &&
    //     item.category.toLowerCase().includes(input.toLowerCase());
    //   const colorCondition =
    //     item.color &&
    //     item.color.find((uniqueColor) => {
    //       return uniqueColor.toLowerCase().includes(input.toLowerCase());
    //     });
    //   const sizeCondition =
    //     item.size &&
    //     item.size.find((uniqueSize) => {
    //       return uniqueSize.toLowerCase().includes(input.toLowerCase());
    //     });
    //   const nameCondition =
    //     item.name && item.name.toLowerCase().includes(input.toLowerCase());
    //   const collectionCondition =
    //     item.name &&
    //     item.subcategory.toLowerCase().includes(input.toLowerCase());
    //   return (
    //     colorCondition ||
    //     sizeCondition ||
    //     nameCondition ||
    //     collectionCondition ||
    //     categoryCondition
    //   );
    // });
  } else if (!category && !subcategory) {
    result = data.products;
  }
  if (filters) {
    result = result.filter((item) => {
      const sizeCondition =
        item.size &&
        item.size.find((uniqueSize) => {
          return uniqueSize.includes(filters);
        });
      const colorCondition =
        item.color &&
        item.color.find((uniqueColor) => {
          return uniqueColor.includes(filters);
        });
      const coleccionCondition = item.subcategory === filters;
      return sizeCondition || colorCondition || coleccionCondition;
    });
  }


  //cuando se ingresa desde la ruta de home
  if (subcategory) {
    result = result.filter((item) => {
      const sizeCondition =
        item.size &&
        item.size.find((uniqueSize) => {
          return uniqueSize.includes(subcategory);
        });
      const colorCondition =
        item.color &&
        item.color.find((uniqueColor) => {
          return uniqueColor.includes(subcategory);
        });
      const coleccionCondition = item.subcategory === subcategory;
      return sizeCondition || colorCondition || coleccionCondition;
    });
  }
  return result;
     
};

export default Plp;
