// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <section className="notfound-container-container">
      <section className="notfound-container">
      <h2>Error 404</h2>
      <p>La página que estás buscando no existe.</p>
    </section>
    </section>
  );
};

export default NotFound;
