

function Seguridad() {



  return (
    <section className="seguridad-datos-container">
        <h1>SEGURIDAD DE DATOS</h1>
     <span>
     <p>Seguridad de datos y Política de Cookies</p>

<p>En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”) vigente en México, le informamos los términos y condiciones del Aviso de Privacidad de Datos Personales (“Aviso de seguridad de datos”) Letsac México S.R.L de C.V. (“WOODART” y/o la “Empresa”) con domicilio en Prolongacion Fulton No. 2, La Loma, 54060 Tlalnepantla de Baz, Méx. así como de sus Empresas Subsidiarias y/o sus Unidades de Negocio.</p>

<p>Finalidad de la recopilación de datos. Los datos personales y financieros que recopilamos los destinamos únicamente a los siguientes propósitos:  </p>

<p>1.  Fines de identificación.</p>

<p>2.  fines estadísticos y de análisis interno.</p>

<p>3.  fines de información y contacto con inversionistas.</p>

<p>4.  información a clientes y consumidores.</p>

<p>5.  reclutamiento y selección de personal.</p>

<p>6.  búsqueda y contratación de proveedores de servicios.</p>

<p>7.  proveerle la información, servicios y productos que nos haya solicitado, informarle sobre cambios en los mismos y evaluar la calidad del servicio que le brindamos.</p>

<p>8.  compras en línea.</p>

<p>9.  levantar pedidos.</p>

<p>10.  entrega de producto(s).</p>

<p>11.  notificaciones sobre el estado de pedidos.</p>

<p>12.  realizar el proceso de pago y la facturación correspondiente, en caso de que así lo requiera.</p>

<p>13.  atender dudas, quejas, comentarios, sugerencias, aclaraciones, devoluciones y dar seguimiento a las mismas.</p>

<p>para contactarlo a través de llamadas telefónicas, comunicaciones por medio de correo electrónico, mensajes de texto (incluyendo SMS, aplicaciones de mensajería y redes sociales), correo ordinario y/o cualquier otro medio de comunicación, en relación a los fines antes mencionados y para informarle de cualquier beneficio a su favor, así como sobre productos, bienes, servicios, ofertas y/o promociones de WOODART®. En la recolección y tratamiento de datos personales que usted nos proporcione, cumplimos todos los principios que marca la Ley: licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</p>

<p>Tratamiento de los datos. La información recabada, salvo su oposición expresada en la dirección electrónica info@woodart.com.mx podrá ser compartida con uno o varios terceros proveedores de servicios seleccionados para manejar y administrar tanto los Portales de internet de la Empresa y/u otros servicios de informática y medios electrónicos y/o de mercadotecnia y/o estadística y/o reclutamiento, por lo que los datos personales podrán ser transferidos a dicho(s) tercero(s) sin fines de lucro, únicamente para cumplir con la prestación de los servicios contratados o que se contraten. De igual manera la Empresa podrá, sin fines de lucro, compartir o transmitir sus datos personales con sus empresas subsidiarias o filiales para los mismos fines establecidos en el párrafo que antecede. En ningún caso comercializaremos, venderemos o rentaremos información personal sobre Usted a un tercero sin un consentimiento previo de su parte. En caso de que sus datos sean transferidos a terceros que presten servicios a la Empresa o a empresas filiales, el tratamiento de estos será en los términos mencionados en este Aviso de Privacidad. Si usted no manifiesta oposición para que sus datos sean transferidos, se entenderá que ha otorgado su consentimiento para que éstos sean utilizados en forma discrecional.</p>

<p>Los datos personales que usted proporcione a la Empresa podrán compilarse y fijarse en una base de datos propiedad de la Empresa o del proveedor que le preste servicios de computación e informática. Se hace la advertencia que la Empresa puede utilizar en sus portales electrónicos “cookies” para confirmar su identificación, personalizar su acceso en los Portales electrónicos y revisar su uso, con el solo propósito de mejorar su navegación y sus condiciones de funcionalidad, toda vez que mediante las citadas “cookies” no se recaban datos personales de los usuarios en términos de la Ley.</p>

<p>POLÍTICA DE COOKIES</p>

<p>¿Qué es una Cookie?</p>

<p>Las cookies son archivos de texto que se descargan automáticamente en tu dispositivo u ordenador cuando navegas en una página de internet, permitiendo al servidor de internet recopilar información sobre el usuario que navega en la página web. Existen diversos tipos de cookies, cada una con diferentes fines, que permiten monitorear las actividades de los usuarios en Internet y ofrecerles una mejor experiencia con base en las páginas visitadas y sus preferencias al accesar a las mismas.</p>

<p>Al igual que otras páginas web, WOODART® usa cookies para guardar tus preferencias de navegación, monitorear las secciones que visitas en nuestra página web y los artículos/servicios en los que muestras interés, con el fin de definir tus preferencias y poder enfocarnos en atenderlas.</p>

<p>A continuación, te ofrecemos una descripción de las cookies que podremos utilizar para recabar tus datos:</p>

<p>Cookies estrictamente necesarias: Son aquellas que permiten la navegación en una página web. Estas cookies permiten el uso de las funciones que forman parte de la página y recordar acciones previas hechas en la misma.</p>

<p>Cookies de rendimiento: Son aquellas que sirven para evaluar la frecuencia con la que un usuario accede a una página web y permiten evaluar el funcionamiento de la página, detectando los errores o fallas que pudiesen llegar a tener.</p>

<p>Cookies de funcionalidad: Son aquellas que sirven para identificar datos asociados a las preferencias del usuario (país de origen, ciudad, idioma, moneda), con el fin de que el usuario pueda tener acceso a estas funciones sin necesidad de actualizar la configuración de la página. Este tipo de cookies también permiten el acceso a contenido de la página, como es el caso de videos o imágenes.</p>

<p>Cookies publicitarias: Son aquellas que sirven para compartir publicidad de acuerdo a los intereses que has manifestado en tus búsquedas. Permiten evaluar el impacto y nivel de interés de los usuarios en la publicidad.</p>

<p>Si ya se ha registrado en alguno de nuestros sitios, estas Cookies:</p>

<p>1.  Nos darán a conocer su historial de preferencias sobre la Página.</p>

<p>2.  Nos serán necesarias para poder acceder a su información de cuenta (almacenada en nuestros servidores), para así poder entregar productos y servicios personalizados.</p>

<p>3.  Nos proporcionarán a nosotros y a nuestros proveedores de servicios información que utilizaremos para personalizar nuestros sitios de acuerdo con sus preferencias. Las Cookies también se requieren para optimizar su experiencia de compras, las cuales le permiten agregar múltiples artículos a su canasta de compras antes de pagar. Usted podrá ajustar su buscador de internet para que éste le notifique cuando reciba una Cookie. Sin embargo, en caso de que decidiera no aceptar las Cookies de nuestros sitios, estará limitando la funcionalidad que podemos brindarle al visitar nuestros sitios.</p>

<p>4.  Nuestros sitios también podrán utilizar una tecnología denominada Tracer Tags. Estas también podrán denominarse Clear GIF’s o Web</p>

<p>5.  Enlaces de terceros: Nuestros sitios podrán incluir enlaces a sitios de internet de nuestros socios comerciales, vendedores y anunciantes/publicistas. Excepto lo establecido en el presente, no le proporcionaremos a terceros su información personal sin su consentimiento previo.</p>

<p>El Portal de la Empresa tiene ligas a otros sitios externos, de los cuales el contenido y políticas de privacidad no son responsabilidad de la Empresa, por lo que el usuario, en su caso, quedará sujeto a las políticas de privacidad de aquellos otros sitios, sin responsabilidad alguna para la Empresa. La protección de la privacidad de los datos personales de menores de edad, incapaces y en estado de interdicción es especialmente importante para la Empresa; por esta razón, cuando tengamos conocimiento de datos personales de personas menores de edad, en estado de interdicción o con alguna incapacidad de las contempladas en términos de las leyes mexicanas, su tratamiento se llevará a cabo cumpliendo en todo momento con los lineamientos y requerimientos establecidos en la Ley.</p>

<p>Medidas de Seguridad. . Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este Aviso de Privacidad, hemos establecido bloqueos físicos y electrónicos, así como equipos de redes con tecnología de seguridad para asegurar la integridad de dicha información.</p>

<p>Contraseñas: Para proporcionarle un mayor nivel de seguridad, el acceso en línea a su información personal está protegido con una contraseña seleccionada por usted. Le recomendamos ampliamente que no divulgue su contraseña a nadie. WOODART® nunca le pedirá su contraseña en cualquier tipo de comunicación que no solicite (incluyendo correspondencia no solicitada tal como cartas, llamadas telefónicas o mensajes de correo electrónico) Ningún tipo de transmisión de datos por medio de internet es 100% segura. Hacemos nuestro mejor esfuerzo por proteger su información personal en relación con el acceso, uso o divulgación no autorizados, pero WOODART® no puede asegurar ni garantizarle la seguridad de la información que nos transmita por medio de nuestros Sitios.</p>

<p>Derechos ARCO.Usted tiene en todo momento el derecho de acceder, rectificar, cancelar u oponerse al tratamiento que le damos a sus datos personales (Derechos ARCO), así como limitar el uso y divulgación de los mismos; derecho que podrá hacer valer enviando directamente su solicitud al correo electrónico: info@woodart.com.mx Dicha solicitud deberá contener por lo menos:</p>

<p>1.  Nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud</p>

<p>2.  Los documentos que acrediten la identidad o, en su caso, la representación legal</p>

<p>3.  La descripción clara y precisa de los datos personales respecto de los que se solicita ejercer alguno de los Derechos ARCO; y</p>

<p>4.  Cualquier otro elemento que facilite la localización de los datos personales.</p>

<p>Modificaciones al Aviso de Privacidad. Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, se comunicará publicando una nota visible en nuestro Portal castel.com.mx o cualquier otro que lo sustituya; Por su seguridad, revise, en todo momento que así lo desee, el contenido de este Aviso de seguridad de datos.</p>

<p>Asimismo, a través de este canal usted podrá actualizar sus datos y especificar el medio por el cual desea recibir información, ya que, en caso de no contar con esta especificación de su parte, la Empresa establecerá el canal que considere pertinente para enviarle información.</p>

<p>Normatividad aplicable.La Empresa se encuentra ubicada en México y todos los asuntos en relación a la Privacidad de Datos, así como al Portal de Internet son regidos por las leyes de México. Si Usted está ubicado en algún otro país distinto de México y nos contacta, por favor tome en cuenta que cualquier información que Usted nos provea será transferida a México, y al momento de ingresar su información Usted autoriza esta transferencia y la aceptación del presente Aviso de Privacidad y su sumisión a las leyes mexicanas.</p>

<p>Este Aviso de Privacidad cumple con los requisitos que marca la Ley (Artículos 15 y 16).</p>

<p>Fecha de última actualización de este Aviso de Privacidad: 01/08/2023.</p>

     </span>
    </section>
  );
}

export default Seguridad;
