import React from "react";
import { Accordion } from "flowbite-react";

const HeaderAccordion = () => {
  return (
    <Accordion collapseAll className="pdp-accordion">
      <Accordion.Panel>
        <Accordion.Title className="accordion-title">
          <a href="/productos">Productos</a>
        </Accordion.Title>
        <Accordion.Content>
          <div className="header-dropdown">
            <Accordion collapseAll className="pdp-accordion">
              <Accordion.Panel>
                <Accordion.Title className="accordion-title">
                  <a href="/categoria/wpc">WPC</a>
                </Accordion.Title>
                <Accordion.Content>
                  <div className="header-dropdown">
                    <a className="header-accordion-text" href="categoria/wpc/quattro">
                      Quattro
                    </a>
                    <a className="header-accordion-text" href="/categoria/wpc/sette">
                      Sette
                    </a>
                    <a className="header-accordion-text" href="/categoria/wpc/panel">
                      Paneles
                    </a>
                    <a className="header-accordion-text" href="/categoria/wpc/Vigas%20y%20Esquineros">
                      Vigas y Esquineros
                    </a>
                    <a className="header-accordion-text" href="/categoria/wpc/Iluminación">
                      Iluminación
                    </a>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-title">
                  <a href="/categoria/spc">SPC</a>
                </Accordion.Title>
                <Accordion.Content>
                  <div className="header-dropdown">
                    <a className="header-accordion-text" href="/categoria/spc/Douglas">
                      Douglas
                    </a>
                    <a className="header-accordion-text" href="/categoria/spc/Lawson">
                      Lawson
                    </a>
                    <a className="header-accordion-text" href="/categoria/spc/Palm">
                      Palm
                    </a>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-title">
                  <a href="/categoria/lvt">LVT</a>
                </Accordion.Title>
                <Accordion.Content>
                  <div className="header-dropdown">
                    <a className="header-accordion-text" href="/categoria/lvt/Forest">
                      Forest
                    </a>
                    <a className="header-accordion-text" href="/categoria/lvt/Concrete">
                      Concrete
                    </a>
                    <a className="header-accordion-text" href="/categoria/lvt/Ozz">
                      Ozz
                    </a>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};

export default HeaderAccordion;
