import React, { useEffect, useState } from "react";
import {useParams } from 'react-router-dom';
import { Modal} from "flowbite-react";
import PlpAccordion from "./PlpAccordion";

function FormModal({ initialProducts, openModal, onCloseModal, setOpenModal }) {
  const { category} = useParams();
  const [products, setProducts] = useState(initialProducts);

  useEffect(() => {
    setProducts(initialProducts);
  }, [category]);


  return (
    <>
      <Modal
        className="form"
        show={openModal}
        size="md"
        onClose={onCloseModal}
        popup
      >
        <Modal.Header className="form-container form-header" />
        <Modal.Body className="form-container plp-accordion-mobile">
            <h2 className="plp-accordion-title-mobile">Filtrar por</h2>
          <PlpAccordion products={products}></PlpAccordion>
          <div className="plp-accordion-mobile-btn-container">
          <button className="product-button" onClick={() => setOpenModal(false)}>Ver productos</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FormModal;
