import "./Areatecnica.css";
import { Link } from 'react-router-dom';

function Areatecnica() {


  return (
    <section className="areatecnica-container">
      <h1 className="areatecnica-title">¡Hazlo divertido!</h1>
      <div className="video-text-container">
      <video autoPlay muted controls preLoad="auto" loop>
        <source src={require("../assets/video1.mp4")} type="video/mp4" />
      </video>
      <article className="areatecnica-text">
      <h2 className="areatecnica-subtitle">Descubre cómo hacerlo</h2>
      <span>
        Todos nuestros productos son de fácil instalación, es por ello que hemos
        creado una serie de videos que te ayudaran a tener el espacio de tus
        sueños.
      </span>
      <Link className="areatecnica-button" to="https://www.youtube.com/@Woodart.2024/videos"target="_blank" >Ver más videos</Link>
      </article>
      </div>
    </section>
  );
}

export default Areatecnica;
