// NotFound.js
import React from 'react';
import instagramlogo from "../assets/instagram.png";
import "./Instagram.css"

const Instagram = () => {
  return (
    <button className="instagram-chat" onClick={() => window.open("https://www.instagram.com/direct/t/17844327735075069", '_blank')}  > 
        <img src={instagramlogo}/>
    </button>
  );
}

export default Instagram;
