import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function FormModal({ openModal, onCloseModal }) {
  const form = useRef();
  const [mensaje, setMensaje] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_number: "",
    user_ubication: "",
    products: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z9lzmzi",
        "template_zzicyhd",
        form.current,
        "HdteBOKurbQdw5iD5"
      )
      .then(
        (result) => {
          setMensaje("El mensaje se envió satisfactoriamente");

          // Limpiar los datos del formulario
          setFormData({
            user_name: "",
            user_email: "",
            user_number: "",
            user_ubication: "",
            products: "",
            message: "",
          });
        },
        (error) => {
          setMensaje("Ocurrió un error, favor de intentarlo más tarde");
          console.log(error.text);
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <>
      <Modal
        className="form"
        show={openModal}
        size="md"
        onClose={onCloseModal}
        popup
      >
        <Modal.Header className="form-container form-header" />
        <Modal.Body className="form-container">
          <div className="space-y-6">
            <h3 className="form-text form-title">
              A un paso más cerca de <p className="bold">estar juntos</p>
            </h3>
            {mensaje ===""? 
            <div>
              <form ref={form} onSubmit={sendEmail}>
                <article className="form-row">
                  <div className="div-form-div">
                    <div className="mb-2 block">
                      <Label className="form-text" value="Nombre" />
                    </div>
                    <TextInput
                      className="form-input"
                      id="name"
                      placeholder="Nombre"
                      required
                      type="text"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        className="form-text"
                        htmlFor="email"
                        value="Correo electrónico"
                      />
                    </div>
                    <TextInput
                      className="form-input"
                      id="email"
                      placeholder="correo@correo.com"
                      type="email"
                      name="user_email"
                      required
                      value={formData.user_email}
                      onChange={handleInputChange}
                    />
                  </div>
                </article>
                <article className="form-row">
                  <div className="div-form-div">
                    <div className="mb-2 block">
                      <Label
                        className="form-text"
                        htmlFor="number"
                        value="Número"
                        
                      />
                    </div>
                    <TextInput
                      className="form-input"
                      id="email"
                      placeholder="1234 5678"
                      type="tel"
                      name="user_number"
                      required
                      value={formData.user_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label className="form-text" value="Ubicación" />
                    </div>
                    <TextInput
                      className="form-input"
                      id="ubication"
                      placeholder="Localidad, Estado"
                      type="text"
                      name="user_ubication"
                      required
                      value={formData.user_ubication}
                      onChange={handleInputChange}
                    />
                  </div>
                </article>
                <div>
                  <div className="mb-2 block">
                    <Label
                      className="form-text"
                      value="Producto(s) de interés"
                    />
                  </div>
                  <TextInput
                    className="form-input-products"
                    id="products"
                    placeholder="Quattro Nero, Douglas Natural..."
                    type="text"
                    name="products"
                    value={formData.products}
                    onChange={handleInputChange}
                  />
                </div>
                <textarea
                  className="form-input-details"
                  id="details"
                  placeholder="Cuentanos sobre tu proyecto"
                  type="text"
                  name="message"
                  required
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
                <div className="form-btn-container">
                  <Button type="submit" value="Send" className="form-button">
                    Realizar Solicitud
                  </Button>
                </div>
               
              
              </form>
            </div>
             :
             <div className="confirmation-message-container">
             <span className="confirmation-message">{mensaje}</span>
             </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FormModal;
