import React, { useState, useEffect, useRef } from "react";
import { Button } from "flowbite-react";
//import { Dropdown } from 'flowbite-react';
import searchIcon from "../search.svg";
import FormModal from "./FormModal";
import HeaderAccordion from "./HeaderAccordion.js";
import logo from "../assets/Logo woodart blanco.png";
import hamburger from "../hamburger.svg";
import "./Header.css";
import close from "../close.svg";
import wpcMenu from "../assets/WPC_MENU.jpg";
import spcMenu from "../assets/SPC_MENU.jpg";
import lvtMenu from "../assets/FOREST NOGAL.webp";


function Header() {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [input, setInput] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [megaMenuBtn, setMegaMenuBtn] = useState("op1");
  const [navBg, setNavBg] = useState(false);

  const headerRef = useRef(null);
//cambiar estilo de header cuando se haga scroll
  const changeNavBg = () =>
  {
   window.scrollY >= 70 ? setNavBg(true) : setNavBg(false);
  }

  useEffect(() => {
  
    function handleClickOutside(event) {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);


    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [headerRef,]);

  window.addEventListener('scroll', changeNavBg);
  const onCloseModal = () => {
    setOpenModal(false);
    setEmail("");
  };

  const handleInput = (event) => {
    setInput(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter" && input !== "") {
      window.location.href = `/search/${input}`;
    } 
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMegamenu = (btn) => {
    setMegaMenuBtn(btn)
  }
  

  return (
    <>
      {isMobile ? (
        <header ref={headerRef} className={isMenuOpen ? "expanded-header" : "" || navBg ? "header-nav-color":""}>
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
          <div className="search-container">
            <input
              type="search"
              className="search-input"
              id="site-search"
              value={input}
              onChange={handleInput}
              onKeyUp={handleKeyUp}
            />
            <a href={input !== "" ? `/search/${input}` : null}>
              <img className="lupaIcon" src={searchIcon} alt="lupa" />
            </a>
          </div>
          <label htmlFor="menuToggle" className="hamburger-icon">
            {isMenuOpen ?
            <img
            className="hamburger-icon"
            src={close}
            alt="hamburger"
            onClick={toggleMenu}
          />
          :
          <img
          className="hamburger-icon"
          src={hamburger}
          alt="hamburger"
          onClick={toggleMenu}
        />

            }
            
          </label>
          {isMenuOpen && (
            <section className="header-container">
              <div className="additional-text">
                <HeaderAccordion></HeaderAccordion>
                <a href="/catalogo">Cátalogo</a>
                <a href="/area-tecnica">Instalación</a>
                <Button className="header-button" onClick={() => setOpenModal(true)}>
                  Quiero comprar
                </Button>
                <FormModal openModal={openModal} onCloseModal={onCloseModal} email={email} setEmail={setEmail}></FormModal>
              </div>
            </section>
          )}
        </header>
      ) : (
        //DESKTOP
        <header className={ navBg ? "header-nav-color":""} ref={headerRef}>
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
          <nav className="header-nav">
          <div className="dropdown"  onMouseLeave={() => handleMegamenu("op1")}>
          <a href="/productos" className="dropbtn">Productos</a>
            <div className="dropdown-content">
              <ul className="dropdown-categories">
                <a href="/categoria/wpc" className={megaMenuBtn==="op1"? "il-selected":"" } onMouseEnter={() => handleMegamenu("op1")}>
                    WPC
                </a>
                <a href="/categoria/spc" className={megaMenuBtn==="op2"? "il-selected":"" } onMouseEnter={() => handleMegamenu("op2")}>SPC</a>
                <a href="/categoria/lvt" className={megaMenuBtn==="op3"? "il-selected":"" } onMouseEnter={() => handleMegamenu("op3")}>LVT</a>
              </ul>
            {megaMenuBtn==="op1" && 
              <>
                <ul className="col1">
                  <span className="col-title">COLECCIONES</span>
                  <a href="/categoria/wpc/quattro">Quattro</a>
                  <a href="/categoria/wpc/sette">Sette</a>
                </ul>
                <ul className="col2">
                <a href="/categoria/wpc/panel">Paneles</a>
                <a href="/categoria/wpc/Vigas%20y%20Esquineros">Vigas y Esquineros</a>
                <a href="/categoria/wpc/Iluminación">Iluminación</a>
                </ul>
                <ul className="col-img">
                  <img className="menu-img" src={wpcMenu} alt="wpc-menu-img"/>
                </ul>
              </>
            }
             {megaMenuBtn==="op2" && 
              <> 
                <ul className="col1">
                  <span className="col-title">COLECCIONES</span>
                  <a href="/categoria/spc/Douglas">Douglas</a>
                  <a href="/categoria/spc/Lawson">Lawson</a>
                  <a href="/categoria/spc/Palm">Palm</a>
                </ul>
                <ul className="col-img">
                <img className="menu-img" src={spcMenu} alt="spc-menu-img"/>
                </ul>
              </> 

            }
             {megaMenuBtn==="op3" && 
             <> 
                <ul className="col1">
                  <span className="col-title">COLECCIONES</span>
                  <a href="/categoria/lvt/Forest">Forest</a>
                  <a href="/categoria/lvt/Concrete">Concrete</a>
                  <a href="/categoria/lvt/Ozz">Ozz</a>
                </ul>
                <ul className="col-img">
                <img className="menu-img" src={lvtMenu} alt="lvt-menu-img"/>
                </ul>
              </> 
            }

             </div>
            </div>
            <a href="/catalogo">Cátalogo</a>
            <a href="/area-tecnica">Instalación</a>
            <div className="search-container">
              <input
                type="search"
                className="search-input"
                id="site-search"
                value={input}
                onChange={handleInput}
                onKeyUp={handleKeyUp}
              />
              <a href={input !== "" ? `/search/${input}` : null}>
                <img className="lupaIcon" src={searchIcon} alt="lupa" />
              </a>
            </div>
            <Button className="header-button" onClick={() => setOpenModal(true)}>
              Quiero comprar
            </Button>
            <FormModal openModal={openModal} onCloseModal={onCloseModal} email={email} setEmail={setEmail}></FormModal>
          </nav>
        </header>
      )}
    </>
  );
}

export default Header;
